async function returnEnvironment() {
	const apiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("api") : bl.util.getBLHost("api");
	const data = fetch(`https://${apiHost}/v3api/common/environment`, {
		method: 'GET',
		credentials: 'include',
	}).then(response => {
		return response.json();
	}).then(obj => {
		return obj.result.environment;
	}).catch(error => {
		console.error('Error:', error);
	});

	return data;
}

async function fetchNavigationContent(type) {
	const cmsApiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("cmsapi") : bl.util.getBLHost("cmsapi");
	
	const data = fetch(`https://${cmsApiHost}/navigation?${type}`, {
		method: 'GET',
	}).then(response => {
		// console.log(response);
		return response.json();
	}).catch(error => {
		console.log(error);
	});

	return data;
}

async function returnBLPHeaderData() {
	let data;

	const getHeaderContent = fetchNavigationContent('header');

	await getHeaderContent.then(function success(entry) {
			// console.log(entry);
			data = replaceHost(entry);
	 }, function error(err) {
	 		console.log(err);
	 });

	 return data;
}

async function returnBLPFooterData() {
	let data;

	const getFooterContent = fetchNavigationContent('footer');

	await getFooterContent.then(function success(entry) {
			// console.log(entry);
			data = replaceHost(entry);
   }, function error(err) {
   		console.log(err);
   });

	 return data;
}

function replaceHost(data) {
	const hostWww = typeof blapp !== 'undefined' ? blapp.config.getHost('www') : bl.util.getBLHost('www');
	const hostStudioForum = typeof blapp !== 'undefined' ? blapp.config.getHost('studioforum') : bl.util.getBLHost('studioforum');

	let result = JSON.stringify(data).replace(/www.bricklink.com/g, hostWww);
			result = result.replace(/forum.bricklink.com/g, hostStudioForum);

	return JSON.parse(result);
}

function returnUserNotifications() {
	const apiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("api") : bl.util.getBLHost("api");
	const data = fetch(`https://${apiHost}/v3api/user/notification`, {
		method: 'GET',
		credentials: 'include',
	}).then(response => {
		if(response.ok) {
			return response.json();
		}else{
			console.log('Error: notification fetch failed');
		}
	}).catch(error => {
		console.error('Error:', error);
	});

	return data;
}

function returnSiteWideNotifications() {
	const apiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("api") : bl.util.getBLHost("api");
	const data = fetch(`https://${apiHost}/v3api/common/siteNotification`, {
		method: 'GET',
		credentials: 'include',
	}).then(response => {
		if(response.ok) {
			return response.json();
		}else{
			console.log('Error: sitewide notification fetch failed');
		}
	}).catch(error => {
		console.error('Error:', error);
	});

	return data;
}

function returnCartCount() {
	const apiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("api") : bl.util.getBLHost("api");
	const data = fetch(`https://${apiHost}/v3api/cart/count`, {
		method: 'GET',
		credentials: 'include',
	}).then(response => {
		if(response.ok) {
			return response.json();
		}else{
			console.log('Error: cart fetch failed');
		}
	}).catch(error => {
		console.error('Error:', error);
	});

	return data;
}

function returnCartSummary() {
	const apiHost = typeof blapp !== 'undefined' ? blapp.config.getHost("api") : bl.util.getBLHost("api");
	const url = new URL(`https://${apiHost}/v3api/cart/summary`);
	const params = { cartCountLimit: 15 };
	url.search = new URLSearchParams(params).toString();

	const data = fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then(response => {
		if(response.ok) {
			return response.json();
		}else{
			console.log('Error: cart summary fetch failed');
		}
	}).catch(error => {
		console.error('Error:', error);
	});

	return data;
}

window.returnBLPHeaderData = returnBLPHeaderData;
window.returnBLPFooterData = returnBLPFooterData;
window.returnUserNotifications = returnUserNotifications;
window.returnSiteWideNotifications = returnSiteWideNotifications;
window.returnCartCount = returnCartCount;
window.returnCartSummary = returnCartSummary;
window.returnEnvironment = returnEnvironment;